import React, { useRef } from 'react';
import styled from "styled-components";

import MapSection from './ContactPage/MapSection';
import ContactSection from './ContactPage/ContactSection';
import SocialMedia from './ContactPage/SocialMedia';

const ContactContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ToggleButton = styled.article`
  background-color: #7600ff;
  color: white;
  padding: 0.3rem 2rem;
  border-radius: 1rem;
  align-self: flex-end;
  width: fit-content;
  text-align: center;
  margin: 1.5rem auto;
  font-size: 10pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: #000;
  }

  @media screen and (min-width: 768px) {
    margin-top: 18px;
    margin-bottom: 18px;
  }
`

const PageContact = ({ intlBtn, clicked }) => {

  const contactRef = useRef(null);

  return (
    <ContactContainer contactRef={contactRef}>
      <MapSection />
      <ContactSection />
      <SocialMedia />
      <ToggleButton onClick={clicked}>{intlBtn}</ToggleButton>
    </ContactContainer>
  )
}

export default PageContact