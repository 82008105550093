import React from "react"
import styled from "styled-components"

import AncreMail from "../../../Ancres/AncreMail"
import AncreTel from "../../../Ancres/AncreTel"
import AncreHrefMaps from "../../../Ancres/AncreHrefMaps"

const TextContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  height: auto;
  padding-left: 1rem;
  padding-bottom: 1rem;
  overflow: visible;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    height: auto;
    padding-left: 1rem;
    padding-bottom: 1rem;
    overflow: visible;
    flex-wrap: wrap;
    font-size: 12pt;
  }
`;

const Paragraph = styled.p`
  font-family: "Perroquet-Bold", Arial, Helvetica, sans-serif;
  font-size: 12pt !important;
  @media(min-width: 630px){
    font-size: 12pt !important;
  }
`;

const ContactSection = () => {
  return (
    <>
      <TextContainer style={{ paddingTop: "1rem" }}>
        <Paragraph>Marseille Innovation</Paragraph>
        <AncreHrefMaps url={"https://www.google.fr/maps/place/Perroquet/@43.3086933,5.3890381,15z/data=!4m12!1m6!3m5!1s0x0:0x3f4bad571caf1edd!2sMarseille+Innovation+-+P%C3%B4le+M%C3%A9dia+Belle+de+Mai!8m2!3d43.3086933!4d5.3890381!3m4!1s0x12c9c1f52b32ca69:0xd6ddffceb69a6976!8m2!3d43.3093219!4d5.3892097"}>37 rue Guibal, 13001 Marseille</AncreHrefMaps>
      </TextContainer>

      <TextContainer>
        <AncreMail></AncreMail>
        <AncreTel tel={"tel:0491055055"}>04 91 05 50 55</AncreTel>
        <AncreTel tel={"tel:+33686127464"}>06 86 12 74 64</AncreTel>
        <AncreMail mail={"mailto:contact@perroquet.eu"}>
          contact@perroquet.eu
        </AncreMail>
      </TextContainer>

      <TextContainer>
        <AncreHrefMaps url={"https://www.google.fr/maps/place/Belle+de+Mai+La+Friche/@43.3086328,5.389371,18z/data=!4m12!1m6!3m5!1s0x12c9c0833a4e8551:0x6d9c7260c259538a!2sFriche+la+Belle+de+Mai!8m2!3d43.310172!4d5.3905693!3m4!1s0x12c9c0833850e00f:0x4a4106e4ccaa7b1!8m2!3d43.3095526!4d5.3902352"}>Bus 49, Belle de Mai la Friche</AncreHrefMaps>
        <AncreHrefMaps url={"https://www.google.fr/maps/dir//pole+media/@43.308793,5.3886733,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12c9c09ca9eaecfb:0x2b8e4abd06b7b224!2m2!1d5.38871!2d43.3084268"}>Bus 56, Pôle Média</AncreHrefMaps>
        <AncreHrefMaps url={"https://www.google.fr/maps/place/Saint-Charles/@43.3026157,5.3780961,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9c096f7362eb5:0x9be0c4919b550fa0!8m2!3d43.3026157!4d5.3802848"}>Métro 1, Gare de Marseille Saint-Charles</AncreHrefMaps>
        <AncreHrefMaps url={"https://www.google.fr/maps/place/Parking+gare+de+Marseille+Saint-Charles+P2+Voltaire+-+EFFIA/@43.303248,5.3820283,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9c09a1978739f:0xfb89935efe2bd792!8m2!3d43.3031913!4d5.3842783"}>Parking, Marseille Saint-Charles Voltaire</AncreHrefMaps>
      </TextContainer>
    </>
  )
}

export default ContactSection
