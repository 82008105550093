import { injectIntl, Link, changeLocale } from "gatsby-plugin-intl"
import React from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import LogotypeMini from "../LogoTypes/LogotypeMini"
import Icon from "../LogoTypes/Logotype"


import * as AnimationsStyle from "../animations.module.scss"

const HeaderContainer = styled.article`
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`

const NavContainer = styled.nav`
  width: 100%;
  padding: 0;
  margin: 0;
  height: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const NavArticle = styled.article`
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Logo = styled(Link)`
  padding: 0.118rem 0.5rem 0.1rem 0.5rem;
  height: 32px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7600ff;
  margin-right: 0.3rem;

  svg path {
    fill: white;
  }
`;

const FakeLogo = styled.button`
  outline: transparent;
  border: transparent;
  padding: 0.118rem 0.5rem 0.1rem 0.5rem;
  height: 32px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7600ff;
  margin-right: 0.3rem;

  svg path {
    fill: white;
  }
`

const Anchor = styled(Link)`
  border-radius: 1.75px;
  background-color: rgba(46, 47, 50, 0.88);
  margin-right: 0.3rem;
  height: 100%;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.88);

  text-decoration: none;
  overflow-wrap: break-word;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #7600ff;
    color: white;
  }
`
const Anchorbtn = styled.button`
  border-radius: 1.75px;
  background-color: rgba(46, 47, 50, 0.88);
  margin-right: 0.3rem;
  height: 100%;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.88);

  outline: none;
  border: none;
  overflow-wrap: break-word;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #7600ff;
    color: white;
  }
`

const MobileSpanFr = styled.span`
  display: flex;
  @media screen and (min-width: 768px) {
    margin-right: 5px !important;
  }
`

const MobileSpanEn = styled.span`
  display: flex;
  @media screen and (min-width: 768px) {
    margin-left: 5px !important;
  }
`

const DesktopSpan = styled.span`
  display: none;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`

const ContactBtn = styled.button`
  height: 100%;
  border-radius: 20px;
  background-color: rgba(46, 47, 50, 0.88);
  margin-right: 0.3rem;
  padding: 0 0.5rem 0 0.5rem;
  display: flex;
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.88);
  line-height: auto;
  outline: none;
  border: transparent;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #7600ff;
    color: white;
  }
`

const BoutonToggleLangue = styled.article`
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: rotate(-360deg);
  }
`

const LangBtn = styled.span`
  color: rgba(255, 255, 255, 0.88);
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 1px;

  height: 32px;
  padding: 0 0.45rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 0px solid #7600ff;
  background-color: rgba(46, 47, 50, 0.88);

  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #7600ff;
  }
`

const CloseBtn = styled.span`
  background-color: rgba(255, 255, 255, 0.85);
  color: rgba(46, 47, 50, 0.88);

  height: 32px;
  width: 32px;
  font-size: 12pt;
  text-transform: uppercase;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: all 0.5s ease-in-out;

  &:hover {
    transform: rotate(-360deg);
    background-color: #7600ff;
    color: rgba(255, 255, 255, 0.85);
  }
`

const MainNav = ({ intl, showCross, showingPopUp }) => {
  //const isBrowser = typeof window !== "undefined"
  var pathnameOfPage = "undefined"
  if (typeof window !== "undefined") {
    pathnameOfPage = window.location.pathname
  } else {
    pathnameOfPage = "/"
  }
  const changerLangue = langue => {
    if (langue === "fr") changeLocale("en")
    else changeLocale("fr")
  }

  const setStyle = () => {
    return {
      fontFamily: "Perroquet-Bold",
      backgroundColor: "#7600FF",
      color: "#fff",
    }
  }

  return (
    <HeaderContainer>
      <NavContainer>
        <NavArticle className={AnimationsStyle.fadeMainNavBar}>
          {pathnameOfPage === "/" ? (
            <FakeLogo to="/" aria-label="Perroquet">
              <LogotypeMini />
              <Icon />
            </FakeLogo>
          ) : (
            <Logo to="/" aria-label="Perroquet">
              <LogotypeMini />
              <Icon />
            </Logo>
          )}

          {/* SI ON EST SUR LA PAGE D'ACCUEIL : SCROLL VERS BUREAU */}
          {pathnameOfPage === "/" ? (
            intl.formatMessage({ id: "lang" }) === "en" ? (
              <Anchorbtn id="btnBureau" activeStyle={setStyle()} onClick={() => { scrollTo("#infos") }}>
                <DesktopSpan>{intl.formatMessage({ id: "deDesignGraphique" })}</DesktopSpan>
                <MobileSpanEn>{intl.formatMessage({ id: "bureau" })}</MobileSpanEn>
              </Anchorbtn>
            ) : (
              <Anchorbtn id="btnBureau" activeStyle={setStyle()} onClick={() => { scrollTo("#infos") }}>
                <MobileSpanFr>{intl.formatMessage({ id: "bureau" })}</MobileSpanFr>
                <DesktopSpan>{intl.formatMessage({ id: "deDesignGraphique" })}</DesktopSpan>
              </Anchorbtn>
            )
          ) : intl.formatMessage({ id: "lang" }) === "en" ? (
            <Anchor id="btnBureau" to="/" activeStyle={setStyle()} onClick={() => { scrollTo("#infos") }}>
              <DesktopSpan>{intl.formatMessage({ id: "deDesignGraphique" })}</DesktopSpan>
              <MobileSpanEn>{intl.formatMessage({ id: "bureau" })}</MobileSpanEn>
            </Anchor>
          ) : (
            <Anchor id="btnBureau" to="/" activeStyle={setStyle()} onClick={() => { scrollTo("#infos") }}>
              <MobileSpanFr>{intl.formatMessage({ id: "bureau" })}</MobileSpanFr>
              <DesktopSpan>{intl.formatMessage({ id: "deDesignGraphique" })}</DesktopSpan>
            </Anchor>
          )}

          {/* ACTIVER LE BG & TEXT COLOR DU BOUTON SUR PAGE PORTFOLIO */}
          {pathnameOfPage === "/portfolio" || pathnameOfPage === "/portfolio/" || pathnameOfPage === "/fr/portfolio" || pathnameOfPage === "/fr/portfolio/" || pathnameOfPage === "/en/portfolio" || pathnameOfPage === "/en/portfolio/" ? 
          (
            <Anchor to="/portfolio/" style={{ fontFamily: "Perroquet-Bold", backgroundColor: "#7600FF", color: "#fff", }}>{intl.formatMessage({ id: "projets" })}</Anchor>
          ) : (
            <Anchor to="/portfolio/">{intl.formatMessage({ id: "projets" })}</Anchor>
          )}

          <ContactBtn onClick={showingPopUp} style={{ backgroundColor: showCross ? "#7500ff" : "", color: showCross ? "#FFFFFF" : "", fontFamily: showCross ? "Perroquet-Bold" : "", }}>{intl.formatMessage({ id: "contact" })}</ContactBtn>
        </NavArticle>

        <NavArticle className={AnimationsStyle.fadeMainNavBar}>
          {!showCross && (
            <BoutonToggleLangue onClick={() => { changerLangue(intl.formatMessage({ id: "lang" })) }}>
              {intl.formatMessage({ id: "lang" }) === "fr" && (
                <LangBtn>Fr</LangBtn>
              )}
              {intl.formatMessage({ id: "lang" }) === "en" && (
                <LangBtn>En</LangBtn>
              )}
            </BoutonToggleLangue>
          )}

          {showCross && <CloseBtn onClick={showingPopUp}>x</CloseBtn>}
        </NavArticle>
      </NavContainer>
    </HeaderContainer>
  )
}

export default injectIntl(MainNav)
