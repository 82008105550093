import React, { useState } from 'react'
import { injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components";
import PageContact from './PageContact';
import PageForm from './PageForm';

const ContactBackground = styled.section`
  background-color: #7600FF60;
  width: 100vw;

  position: fixed;
  left: 0;
  z-index: 1000;

  transition: opacity 1s ease;
  opacity: ${({ background }) => (background ? "1" : "0")};
  height: ${({ background }) => (background ? "100vh" : "0")};
  bottom: ${({ background }) => (background ? "0" : "100%")};
  transform: ${({ background }) => background ? "translateY(0)" : "translateY(100%)"};

  scrollbar-width: 0px;
  scrollbar-color: transparent;
`;

const PopUpContainer = styled.aside`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  z-index: 1600;
  top: 3rem;
  right: 5vw;
  bottom: 4rem;
  width: 90vw;
  max-width: 600px;
  min-height: 90vh;
  background-color: white;
  color: #7600ff;

  transition-property: bottom, transform;
  transition-duration: 1s;
  transition-timing-function: ease;

  overflow-y: scroll;

  ${'' /* opacity: ${({ showPopUp }) => (showPopUp ? "100%" : "0")}; */}
  bottom: ${({ showPopUp }) => (showPopUp ? "4rem" : "100%")};
  transform: ${({ showPopUp }) => showPopUp ? "translateX(0)" : "translateX(120%)"};
 
  overflow-y: scroll;

  p { color: black !important; }
  z-index: 1600;

  @media screen and (min-width: 768px) {
    flex: 1 1;
    top: 3rem;
    min-height: 90vh;
  }
`;

const ContactModal = ({ intl, background, showPopUp, showingPopUp }) => {

  const [showForm, setShowForm] = useState(false);
  
  return (
    <ContactBackground background={background} showingPopUp={showingPopUp}>
        <PopUpContainer showPopUp={showPopUp}>
        {!showForm && (
          <PageContact showForm={showForm}
            clicked={() => { setShowForm(true)}}
            intlBtn={intl.formatMessage({ id: "posez" })}    
          />
        )}

        {showForm && (        
          <PageForm 
            clicked={() => { setShowForm(false)}}
            returnIntl={intl.formatMessage({ id: "retour" })}
          />
        )}
        </PopUpContainer>
      </ContactBackground>
  )
}

export default injectIntl(ContactModal)
