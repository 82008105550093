import React, { Component } from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"


const HeaderArticle = styled.article`
  width: auto;
  padding-top: 20px;
  margin: 0 1rem 2rem 1rem;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const HeaderParagraphe = styled.p`
  color: #000;
  font-size: 1rem;
  line-height: 1.3rem;

  @media screen and (min-width: 820px){
    font-size: 1.35rem;
    line-height: 1.8rem;
  }
`

class HeaderForm extends Component {
  render() {
    return (
      <HeaderArticle>
        <HeaderParagraphe>
          {this.props.intl.formatMessage({ id: "annonce1" })}
        </HeaderParagraphe>
        <HeaderParagraphe>
          {this.props.intl.formatMessage({ id: "annonce2" })}
        </HeaderParagraphe>
        <HeaderParagraphe>
          {this.props.intl.formatMessage({ id: "annonce3" })}
        </HeaderParagraphe>
      </HeaderArticle>
    )
  }
}

export default injectIntl(HeaderForm)
