import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import MainNav from "./MainNav";
import ContactModal from "./Contact/ContactModal";

const HeaderContainer = styled.section`
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`

const HeaderNav = () => {

  const [showPopUp, setShowPopUp] = useState(false);
  const [background, setBackground] = useState(false);
  const [showCross, setShowCross] = useState(false);

  const showingPopUp = () => {
    setShowPopUp(!showPopUp);
    setBackground(!background);

    setShowCross(!showCross)
  }

  useEffect(() => {
    if(showPopUp === true){
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.overflowY = "scroll";
    } 

    return () => {
      document.body.style.position = "static";
      document.body.style.overflow = "unset";
    }
  }, [showPopUp])

  return (
    <HeaderContainer>

      <MainNav showCross={showCross} showingPopUp={showingPopUp} />

      <ContactModal background={background} showPopUp={showPopUp} showingPopUp={showingPopUp} /> 

    </HeaderContainer>
  )
}

export default HeaderNav
