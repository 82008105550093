import React from "react";
import styled from "styled-components";

import mapsImage from '../../../../img/maps-Perroquet.png'

const MainContainer = styled.section`
  width: 100%;

  padding: 0px;
  overflow: hidden !important;
  transition: height 1s ease;

  @media screen and (max-width: 1450px) {
    height: 25vh !important;
    width: 600px;  
  }
  @media screen and (max-height: 568px){
    height: 15vh;
  }
  @media screen and (min-width: 1450px) {
    height: 35vh;
    width: 600px ;  
  }
`;

const MapImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const MapSection = () => {

  return (
    <MainContainer>
      <MapImg src={mapsImage} alt="Position géographique de Perroquet" />
    </MainContainer>
  )
}

export default MapSection
