import React from 'react';
import styled from "styled-components";
import HeaderForm from './FormPage/HeaderForm';
import MainForm from './FormPage/MainForm';

const RetourBtn = styled.article`
  border: 1px solid #ccc;
  background-color: transparent;
  border-radius: 50px;
  padding: 0.3rem 2rem;
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  color: #777;
  font-size: 10pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  position: absolute;
  left: 50%;
  bottom: 18px;
  transform: translateX(-50%);
`;

const PageForm = ({ clicked, returnIntl}) => {
  return (
    <div>
      <HeaderForm />
      <MainForm clicked={clicked} />
      <RetourBtn onClick={clicked}>{returnIntl}</RetourBtn>
    </div>
  )
}

export default PageForm