import React from 'react';
import styled from 'styled-components';

const Anchor = styled.a`
  color: #000;
  font-size: 12pt;
  text-decoration: none;

  width: fit-content;
  font-family: "Perroquet-Bold",Arial, Helvetica, sans-serif;
  box-shadow: inset 0 -1px 0 #7600ff, 0 1px 0 #7600ff;
  cursor: pointer;
  transition: box-shadow .45s ease-in-out, color .35s ease-in-out;
  &:hover {
    box-shadow: inset 0 -25px 0 #7600ff, 0 1px 0 #7600ff;
    color: #FFF;
  }
`

const AncreMail = ({ mail, children }) => {
  return (
    <Anchor href={mail}>
      {children}
    </Anchor>
  )
}

export default AncreMail
