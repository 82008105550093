import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"

const FormContainer = styled.form`
  padding: 1rem 1rem 15px 1rem;

  @media screen and (min-width: 768px) {
    padding: 1rem 1rem 0.3rem 1rem;
  }

  &:focus {
    outline: none;
  }
`

const FormArticle = styled.article``

const Label = styled.label`
  display: block;
  color: #000;
  font-size: 14px;
`

const Input = styled.input`
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #7600ff;
  margin-bottom: 2rem;
  outline: none;
  caret-color: #7600ff;
  color: #7600ff;

  &::placeholder {
    color: #7600ff !important;
    text-transform: capitalize;
  }

  &:focus {
    color: #7600ff;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #7600ff;
  outline: none;
  caret-color: #7600ff;
  margin-bottom: 1rem;
  color: #7600ff;

  &::placeholder {
    color: #7600ff !important;
    text-transform: capitalize;
  }
  &:focus {
    color: #7600ff;
  }
`

const Checkbox = styled.input`
  margin-top: 0.1rem;
  border-radius: 50%;
  margin-right: 1rem;
  width: 1rem !important;
  height: 1rem;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #7600ff !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: flex;
  cursor: pointer;

  &:active,
  &:checked:active {
    background-color: #7600ff;
  }

  &:checked {
    background-color: #7600ff;
    color: #99a1a7;
  }

  &:checked:after {
    font-size: 14px;
    position: absolute;
    top: 74.5%;
    color: #ffffff;
    display: none !important;
    margin-left: -0.3rem;
  }
`

const SubmitBtn = styled.button`
  display: block;
  background-color: #7600ff;
  width: auto;
  color: white;
  font-size: 10pt;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 0.35rem 1.8rem;
  align-self: flex-end;
  text-align: center;
  cursor: pointer;

  position: absolute;
  left: 50%;
  bottom: 70px;
  transform: translateX(-50%);
`

const MainForm = ({ intl }) => {
  const [formValid, setFormValid] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [objet, setObjet] = useState("")
  const [message, setMessage] = useState("")
  const [pdc, setPdc] = useState(false)
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [objetError, setObjetError] = useState("")
  const [MessageError, setMessageError] = useState("")
  const [pdcError, setPdcError] = useState("")

  useEffect(() => {
    console.log(pdc)
    validateField()
  }, [name,email,objet,message,pdc,formValid])

  const handleChange = (name, value) => {
    switch (name) {
      case "name":
        setName(value)
        validateField(name, value)
        break
      case "email":
        setEmail(value)
        validateField(name, value)
        break
      case "objet":
        setObjet(value)
        validateField(name, value)
        break
      case "message":
        setMessage(value)
        validateField(name, value)
        break
      default:
        validateField(name, value)
    }
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "email":
        let fieldValidationErrors = formErrors
        let emailValid = emailValid
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors = emailValid
          ? " "
          : "L'adresse email n'est pas valide"
        setEmailError(fieldValidationErrors)
        setEmailValid(emailValid?true:false)
        break
      case "name":
        if (!name) {
          setNameError("Veuillez entrer votre nom.")
        } else {
          setNameError("")
        }
        break
      default:
        break
    }

    if (!name) {
      setNameError("Veuillez entrer votre nom.")
    } else {
      setNameError("")
    }
    if (!objet) {
      setObjetError("Veuillez entrer un objet.")
    } else {
      setObjetError("")
    }

    if (!message) {
      setMessageError("Veuillez entrer votre message.")
    } else {
      setMessageError("")
    }
    if (fieldName === "pdc") {
      if (!value) {
        setPdcError("Veuillez accepter les politiques de confidentialités.")
      } else {
        setPdcError("")
      }
    }
    if (
      emailValid &&
      name !== "" &&
      message !== "" &&
      objet !== "" &&
      pdc !== false
    ) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }

  const togglePdc = () => {
    if (pdc) {
      setPdc(false)
      validateField("pdc", false)
    } else {
      setPdc(true)
      validateField("pdc", true)
    }
  }

  return (
    <FormContainer
      name="contactForm"
      action={process.env.GATSBY_FORMSPREE_CONTACTFORM}
      method="POST"
    >

      <FormArticle>
        <Label>{nameError}</Label>
        <Input
          type="text"
          name="name"
          onChange={e => handleChange("name", e.target.value)}
          placeholder={`${intl.formatMessage({ id: "nom" })}`}
        ></Input>
      </FormArticle>

      <FormArticle>
        <Label>{emailError}</Label>
        <Input
          type="email"
          name="email"
          onChange={e => handleChange("email", e.target.value)}
          placeholder={`${intl.formatMessage({ id: "mail" })}`}
        ></Input>
      </FormArticle>

      <FormArticle>
        <Label>{objetError}</Label>
        <Input
          type="text"
          name="objet"
          onChange={e => handleChange("objet", e.target.value)}
          placeholder={`${intl.formatMessage({ id: "objet" })}`}
        ></Input>
      </FormArticle>
      

      <FormArticle>
        <Label>{MessageError}</Label>
        <TextArea
          rows="4"
          name="message"
          onChange={e => handleChange("message", e.target.value)}
          placeholder={`${intl.formatMessage({ id: "message" })}`}
          ></TextArea>
      </FormArticle>

      <FormArticle
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Checkbox
          type="checkbox"
          name="pdc"
          onChange={e => togglePdc(e)}
        ></Checkbox>
        <Label htmlFor="scales">{intl.formatMessage({ id: "jaccepte" })}</Label>
      </FormArticle>
      <p>{pdcError}</p>

      <SubmitBtn
        type="submit"
        disabled={!formValid}
        style={
          !formValid
            ? { backgroundColor: "#ccc", cursor: "not-allowed" }
            : { backgroundColor: "#7500ff" }
        }
      >
        {intl.formatMessage({ id: "envoyez" })}
      </SubmitBtn>
    </FormContainer>
  )
}

export default injectIntl(MainForm)
