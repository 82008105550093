import React from 'react';
import styled from 'styled-components';

const AnchorMaps = styled.a`
  color: #000000;
  font-size: 12pt;
  text-decoration: none;
  width: fit-content;
  font-family: "Perroquet-Bold", Arial, Helvetica, sans-serif;
  box-shadow: inset 0 -1px 0 #7600ff, 0 1px 0 #7600ff;
  cursor: pointer;
  transition: box-shadow .45s ease-in-out, color .45s ease-in-out;
  &:hover {
    color: #FFFFFF;
    box-shadow: inset 0 -25px 0 #7600ff, 0 1px 0 #7600ff;
  }
`;

const AncreHrefMaps = ({ url, children }) => {
  return (
    <AnchorMaps href={url} target="_blank">
      {children}
    </AnchorMaps>
  )
}

export default AncreHrefMaps
