import React, { Component } from "react";
import "./logo.css"

class LogotypeMini extends Component {
  render() {
    var colored = "#000"
    if (this.props.color === "purple") {
      colored = "#fff"
    }
    return (
      <svg className="logodes1" fill={colored} width="16" height="18" xmlns="http://www.w3.org/2000/svg">
        <title>Perroquet-logotype-noir</title>
        <g>
          <title>Layer 1</title>
          <path stroke="null" id="svg_8" d="m12.16778,8.66621a2.64277,2.64277 0 0 1 -0.46452,1.20525c-0.37664,0.45197 -1.07343,0.96671 -3.13868,0.96671l-1.4124,0l0.68423,-3.91079l1.88321,0c2.14058,0 2.36656,0.44569 2.45444,0.62774a2.47328,2.47328 0 0 1 0,1.11109m-2.24101,-4.39415l-0.79095,0l-2.44189,0l-1.08598,0.03139l-2.51094,14.22448l2.69926,0l0.87883,-5.02188l2.22218,0c2.24729,-0.0565 3.76641,-0.6654 4.82728,-1.88321a5.36086,5.36086 0 0 0 1.0546,-2.44817a4.73312,4.73312 0 0 0 -0.21343,-2.7432c-0.88511,-1.83926 -2.81853,-2.15941 -4.63896,-2.15941" />
        </g>
      </svg>
    );
  }
}

export default LogotypeMini;